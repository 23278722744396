import React from 'react';
import styles from './_LandingPage.module.scss';
import d4urcLogo from 'assets/logos/Ford_D4URC_Blue_Screen_RGB_v2.png';
import marketingImage from 'assets/images/marketing_image.jpg';

const LandingPageView = () => {
  return (
    <div className={styles.landingPage}>
      <div className={styles.logoContainer}>
        <img 
          src={d4urcLogo}
          alt="Drive 4 UR Community"
          className={styles.logo}
        />
      </div>

      <div className={styles.marketingContainer}>
        <img 
          src={marketingImage}
          alt="Ford Drive 4 UR Community Event"
          className={styles.marketingImage}
        />
      </div>

      <div className={styles.actionContainer}>
        <a 
          href="https://enroll.d4urs.com/signup"
          className={styles.button}
          target="_blank"
          rel="noopener noreferrer"
        >
          Sign Up
        </a>
        <a 
          href="https://enroll.d4urs.com/login"
          className={styles.button}
          target="_blank"
          rel="noopener noreferrer"
        >
          Login
        </a>
      </div>

      <div className={styles.helpText}>
        <p>
          If you are event staff looking for your portal,
          <br />
          please reach out to your dealer for the Magic Link in their email
        </p>
      </div>
    </div>
  );
};

export default LandingPageView;
