import jstz from 'jstz';
import { CLEAR_FIELDS } from '../types';
import {
  signinAuth,
} from './';

export function submitForm(access_key, data, state) {
  return function(dispatch, API, getState) {
    const { language } = getState().app;
    const { fields } = data;

    let emailOptIn = null;
    let acceptsSms = null;
    let brochureLanguage = 'en';
    let formattedPhone = fields.phone;

    if (fields.email_opt_in === 'Yes' || fields.email_opt_in === 1) {
      emailOptIn = 1;
    }

    if (fields.accepts_sms === 'Yes' || fields.accepts_sms === 1) {
      acceptsSms = 1;
    }

    if (fields.phone && !/^\\d{3}-\\d{3}-\\d{4}$/.test(fields.phone)) {
      formattedPhone = formatPhoneNumber(fields.phone);
    }

    if (fields.brochure_language === true) {
      brochureLanguage = 'es';
    }

    const survey = {
      /* non-UI fields */
      event_id: fields.event_id || null,
      survey_date: new Date(),
      survey_type: fields.survey_type,
      device_survey_guid: fields.device_survey_guid,
      device_id: window.navigator.userAgent,
      event_series: fields.event_series,
      app_version: APP_VERSION,
      abandoned: 0,
      start_time: fields.start_time,
      end_time: new Date(),
      pre_drive_survey_guid: fields.pre_drive_survey_guid || null,
      dealer_portal_entry: fields.dealer_portal_entry,
      checked_in: fields.checked_in || null,
      checked_out: fields.checked_out || null,

      /* personal */
      first_name: fields.first_name.trim() || null,
      last_name: fields.last_name.trim() || null,
      address1: fields.address1 || null,
      address2: fields.address2 || null,
      city: fields.city || null,
      state: fields.state || null,
      country_code: fields.country_code || 'USA',
      zip_code: fields.zip_code || null,

      /* contact */
      phone: formattedPhone || null,
      email: fields.email || null,
      email_opt_in: emailOptIn || null,
      age: fields.age === 'Prefer Not to Answer' ? null : fields.age || null,
      gender: fields.gender || null,
      ethnicity: fields.ethnicity || null,

      /* familiar */
      how_familiar: fields.how_familiar || null,
      how_familiar_other: fields.how_familiar_other || null,

      /* impression */
      impression_pre: fields.impression_pre || null,
      impression_post: fields.impression_post || null,

      /* purchasing */
      how_likely_purchasing_pre: fields.how_likely_purchasing_pre || null,
      how_likely_purchasing_post: fields.how_likely_purchasing_post || null,
      how_likely_acquire: fields.how_likely_acquire || null,

      /* recommend */
      how_likely_recommend_pre: fields.how_likely_recommend_pre || null,
      how_likely_recommend_post: fields.how_likely_recommend_post || null,

      /* likely_buy_model */
      most_likely_buy_make_id_pre: fields.most_likely_buy_make_id_pre || null,

      /* driven most */
      vehicle_driven_most_make_id: fields.vehicle_driven_most_make_id || null,

      /* other */
      in_market_timing: fields.in_market_timing || null,
      custom_data: setCustomData(fields),
      signature: fields.signature || null,
      ca_signed: fields.initials.length > 0 ? 1 : 0,
      ca_signature: fields.initials || null,
      ca_resident_initials: fields.initials.length > 0 ? 1 : 0,
      rep_initials: null,
      certify_age: fields.certify_age || null,
      language: language || 'en',
      brochure_language: (fields.survey_type === 'preTD') ? null : brochureLanguage,

      /* virtual experience pre td */
      comfort_level_pre: fields.comfort_level_pre || null,

      /* physical experience post-td */
      comfort_level_post: fields.comfort_level_post || null,
      overall_physical_experience: fields.overall_physical_experience || null,

      /* virtual experience post-td*/
      overall_virtual_experience: fields.overall_virtual_experience || null,
      virtual_improvements: fields.virtual_improvements.length ? JSON.stringify(fields.virtual_improvements) : null,

      /* VOI */
      vehicles_of_interest: fields.vehicles_of_interest || null,

      /* non-UI */
      drove_bonus_vehicle: fields.drove_bonus_vehicle || null,
      user_name: null,
      signed: 1,
      accepts_sms: acceptsSms,
      timezone: jstz.determine().name(),
      event_type: state.eventType,
    };
    
    // if selected charity exists (meaning 2 charities exist for the event), add it to custom_data.
    if (fields.selected_charity) {
      survey.custom_data = JSON.stringify({ ...JSON.parse(survey.custom_data || '{}'), selected_charity: fields.selected_charity });
    }

    return API.survey.submit({ survey }, access_key)
      .then((response) => {
        dispatch({
          type: CLEAR_FIELDS,
          payload: true,
        });

        return Promise.resolve(response);
      })
      .catch(error => {
        return Promise.reject(error);
      });
  };
}

const setCustomData = (fields) => {
  if (fields.survey_type === 'preTD' && fields.which_td) {
    return JSON.stringify({ which_td: fields.which_td });
  }
  return fields.custom_data ? JSON.stringify(fields.custom_data) : null;
};

/* triggered on manual check-out */
export function sendPostDriveLink(survey) {
  return function(dispatch, API) {
    const {
      hash_id,
      email,
      phone,
      survey_id,
      device_survey_guid,
      eventType,
      charityPartner,
      language,
    } = survey;

    const email_address = email;
    const timezone = jstz.determine().name();

    if (!email && !phone) return;

    const sendLink = [
      API.survey.postDriveSurveyLink({
        hash_id,
        email_address,
        phone,
        survey_id,
        device_survey_guid,
        timezone,
        event_type: eventType,
        charity_name: charityPartner,
        language,
      }),
    ];

    return Promise.all(sendLink)
      .then(() => {
        return Promise.resolve(true);
      })
      .catch(error => {
        return Promise.reject(error);
      });
  };
}

export function submitCreateEventForm(data) {
  return function(dispatch, API) {
    const {
      fields: {
        event_name,
        event_type,
      },
    } = data;

    return API.demo.createEvent({ event_name, event_type })

      .then((response) => {
        dispatch({
          type: CLEAR_FIELDS,
          payload: true,
        });

        return dispatch(signinAuth(response.data.ffs_event_id, response.data.digital_event_code));
      })
      .catch(error => {
        return Promise.reject(error);
      });
  };
}

export function verifyPhone(phone, event_id) {
  return function(dispatch, API) {
    if (!phone) return;
    return API.survey.phoneVerification({ phone, event_id })
      .then(response => {
        return Promise.resolve(response.data.valid);
      })
      .catch(error => {
        return Promise.reject(error);
      });
  };
}

export function verifyEmail(email) {
  return function(dispatch, API) {
    if (!email) return;
    return API.survey.emailVerification({ email })
      .then(response => {
        return Promise.resolve(response.data);
      })
      .catch(error => {
        return Promise.reject(error);
      });
  };
}

function formatPhoneNumber(phone) {
  const cleaned = (`${phone}`).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `${match[1]}-${match[2]}-${match[3]}`;
  }
  return null;
}
